
























































import Vue from "vue";
import { Action } from "vuex-class";
import { Component } from "vue-property-decorator";

import api from "@/core/utils/api";

@Component
export default class ConfirmEmail extends Vue {
  @Action("displaySnackbar") displaySnackbar!: (s: string) => void;

  error: any;
  loading = false;
  done = false;
  async confirm() {
    const { email, token } = this.$route.query;
    if (!email || !token) {
      const msg = this.$t("snack.auth.fail").toString();
      this.displaySnackbar(msg);
      this.$router.push("/auth/login");
      return;
    }
    this.loading = true;
    try {
      const end = "/api/Account/ConfirmEmail";
      const data = { email, token };
      await api.post(end, data);
      this.done = true;
    } catch (error) {
      this.error = error;
      const msg = this.$t("snack.auth.confirmfail").toString();
      this.displaySnackbar(msg);
    }
    this.loading = false;
  }

  created() {
    this.confirm();
  }
}
